import { computed, type ComputedRef, toRef } from "vue";
import { useI18n } from "vue-i18n";

export interface BtnSelectOption {
  label: string;
  value: boolean | string | null | number | undefined;
  onValue?: () => void;
  color?: ColorVariant;
}
export const useBtnSelectOptions = ({
  options,
  enableAll = false,
  allValue = undefined,
}: {
  options?: ComputedRef<BtnSelectOption[]> | BtnSelectOption[];
  enableAll?: boolean;
  allValue?: boolean | string | null | number | undefined;
}) => {
  const { t } = useI18n();
  const opt = toRef(options);

  const extendedOptions = computed(() => {
    let extendedOptions = opt.value ? [...opt.value] : undefined;

    if (!extendedOptions) {
      extendedOptions = [
        {
          label: t("yes"),
          value: true,
        },
        {
          label: t("no"),
          value: false,
        },
      ];
    }

    if (enableAll) {
      extendedOptions = [
        {
          label: t("all"),
          value: allValue,
        },
        ...extendedOptions,
      ];
    }

    return extendedOptions;
  });

  return {
    options: extendedOptions,
  };
};
